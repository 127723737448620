<template>
  <div class="about">
    <loader v-if="loading"></loader>
    <table-component
      v-else
      :items="items"
      :edit="true"
      :delet="true"
      :pages="pages"
      @change_page="changePage"
      @delete-button="deleteButton"
    ></table-component>
  </div>
</template>
<script>
import Loader from "@/components/Loader";
import TableComponent from "@/components/TableComponent";
import users from "@/model/users.js";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    TableComponent,
    Loader,
  },
  data() {
    return {
      loading: true,
      items: {
        head: [
          { id: 1, name: "ID" },
          { id: 2, name: "Дата добавления" },
          { id: 3, name: "Дата обновления" },
          { id: 4, name: "Телефон" },
          { id: 5, name: "Имя" },
          { id: 6, name: "Почта" },
          { id: 7, name: "Роль" },
          { id: 8, name: "Удален" },
        ],
        body: [],
      },
      pageInput: {
        page: 0,
        pageSize: 9,
      },
      pages: {
        count: 0,
        current: 0,
        elements: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      USER: "User/STATE",
    }),
  },
  async created() {
    await this.checkPower();
    await this.setBody();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getUsers: "User/GET_ALL_USER",
      deleteUser: "User/DELETE_USER",
    }),
    checkPower() {
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        this.$router.push("/");
      }
    },
    async changePage(val) {
      this.loading = true;
      this.pageInput.page = val;
      await this.setBody();
      this.loading = false;
    },
    async setBody() {
      await this.getUsers(this.pageInput);
      let model = new users();
      this.items.body = model.setBody(this.USER.allUser);
      this.pages = model.setPages(this.USER.allUser);
    },
    async deleteButton(item) {
      this.loading = true;
      let errors = [];
      for (let i = 0; i < item.length; i++) {
        let response = await this.deleteUser(item[i].id);
        if (response.type !== "success") {
          errors.push(item[i].id);
        }
      }
      if (errors.length !== 0) {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: errors,
        });
      }
      await this.setBody();
      this.loading = false;
    },
  },
};
</script>
